@import '../../../../../../../global/fonts.scss';

.listing-table .table-responsive {
	overflow: visible;
}

.created-ai-articles-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.created-ai-articles-title {
  color: #000;
  @include OpenSansFont(600, 24px, 16px);
  font-style: normal;
}

.created-ai-articles-description {
  color: #000;
  @include OpenSansFont(400, 20px, 14px);
  font-style: normal;
}
