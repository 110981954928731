@import '../../../../global/fonts.scss';

.custom-toast-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  .Toastify__toast-container {
    border-radius: 0 8px 8px 0;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 4px;
    right: 35px;
  }

  .Toastify__toast {
    display: flex;
    flex-direction: row;
    padding: 8px 8px 8px 16px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 0 8px 8px 0;
    background: #FFF;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.10);
  }

  .Toastify__toast-body {
    display: flex;
    width: 298px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
}

.custom-toast-title  {
  color: #000;
  @include OpenSansFont(600, 20px, 14px);
  font-style: normal;
  display: block;

}

.custom-toast-message {
  color: #000;
  @include OpenSansFont(400, 20px, 14px);
  font-style: normal;
  display: block;
}


// Different border colors based on the type
.success {
  border-left: 4px solid #28a745;
}

.warn {
  border-left: 4px solid #FFD800;
}

.error {
  border-left: 4px solid #D92033;
}





