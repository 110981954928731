@import '../../../../global/fonts.scss';


.competition-filter-container {
  max-width: 100%;
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background:  #FAFAFA;
  margin-bottom: 8px;

  .competition-filter-title {
    color: #0F172A;
    @include OpenSansFont(600, 20px, 14px);
    font-style: normal;
  }

  .competition-filer-info-container {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 4px;

    .competition-filter-info-label {
      color:  #8C8C8C;
      @include OpenSansFont(400, 16px, 11px);
      font-style: normal;
    }

    .competition-filter-info-icon {
      width: 8px;
      height: 8px;
      background-image: url('../../../../assets/icons/general/competition-filter-info-icon.svg');
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.competition-filter-events-container {
  max-width: 100%;
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  margin-top: 8px;
  margin-bottom: 16px;
}

  .events-container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;

    .events-group-date-label {
      color: #434343;
      @include OpenSansFont(700, 20px, 14px);
      font-style: normal;
    }

    .events-group-competition-label {
      color: #434343;
      @include OpenSansFont(400, 20px, 14px);
      font-style: normal;
    }
  }

    .events-group-elements-container {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: flex-start;
      align-content: center;
      gap: 8px;
      flex-wrap: wrap;
      margin-top: 8px;
      margin-bottom: 16px;
      border-radius: 6px;
      background: #FAFAFA;
      padding: 16px;
      position: relative;
      max-width: 100%;
      width: 100%;
    }

    .events-group-elements-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      align-items: flex-start;
      width: 100%;
      max-width: 100%;
    }

      .events-group-element-pill {
        display: flex;
        height: 26px;
        padding: 4px 8px;
        align-items: center;
        gap: 4px;
        border-radius: 4px;
        border: 1px solid #D9D9D9;
        background: #F0F0F0;
        min-width: auto; /* Ensures they shrink properly */
        max-width: 100%; /* Prevents overflow */
        flex-wrap: wrap;

      }

      .events-group-element-pill-label {
        color: #434343;
        text-align: center;
        @include OpenSansFont(400, 18px, 11px);
        font-style: normal;
      }

      .events-group-element-pill-remove-icon {
        width: 16px;
        height: 16px;
        background-image: url('../../../../assets/icons/general/close.svg');
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
      }

      .remove-group-icon-container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
      }

    .events-group-element-section-remove-icon {
      width: 24px;
      height: 24px;
      background-image: url('../../../../assets/icons/general/close.svg');
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
    }

      .competition-logo {
        mix-blend-mode: luminosity;
        width: 16px;
        height: 16px;
      }



