@import '../../../../../../../global/fonts.scss';


.override-form-group {
	margin-bottom: 0px;
	display: flex;
	align-items: center;
	margin-top: 0px;
	& > .form-check-input {
		margin-left: 0px;
		position: relative;
		margin-right: 8px;
		margin-top: 0px;
	}
}

.error-message {
	display: flex;
	align-items: center;
	gap: 4px;
	margin-top: 4px;
	color: #e25160;
	font-size: 11px;

	& > i {
		font-size: 16px;
	}
}

.invalid-field {
	.css-yk16xz-control {
		border-color: #e25160 !important;
	}
}

.show-competitions-container {
	display: flex;
	flex-direction: column;
	gap: 8px;
	background-color: #ffffff;
	padding: 16px;
	margin-top: 8px;
	border-radius: 8px;
	max-height: 400px;
	overflow-y: auto;
}

.hide-competitions-container {
	display: none;
}

.selected-input {
	.css-yk16xz-control {
		background-color: #fafafa;
		border-color: #d9d9d9;
	}

	.css-1rhbuit-multiValue {
		border-color: #d9d9d9;
		background-color: #f0f0f0;
		border-radius: 4px;
		gap: 4px;
		padding: 4px 5px 4px 8px;
		margin: 4px;
		height: 26px;
		align-items: center;
	}

	.css-12jo7m5 {
		color: #434343;
		font-size: 11px;
	}

	.css-1alnv5e,
	.css-1alnv5e:hover {
		color: #bfbfbf;
		background-color: #f0f0f0;
		cursor: pointer;
	}

	.css-1g48xl4-IndicatorsContainer {
		cursor: pointer;
	}
}

.selected-input-label {
	color: #434343;
	font-weight: 700;
	font-size: 14px;
}

.modal-header-container {
	border: none;
	// padding: 16px 24px 24px 24px;
	& > .modal-title > span {
		font-size: 20px;
		font-weight: 700;
		color: #0f172a;
	}
	& > .close {
		color: #0f172a;
		opacity: 1;
	}
}

.modal-header-description {
	color: #434343;
	font-size: 16px;
	margin-bottom: 0px;
	padding-block: 16px;
}

.select-all-competitions-label {
	color: #0f172a;
	font-size: 16px;
	font-weight: 600;
}

.modal-body-container {
	padding: 0px;
	max-height: 500px;
	overflow-y: auto;
}

.competition-list-section {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 8px 24px;
	background-color: #fafafa;
	height: 40px;
}

.competition-list-section-wrapper {
	display: flex;
}

.competition-list-section-label {
	display: flex;
	gap: 4px;
	align-items: center;
	color: #434343;
	font-size: 14px;
	font-weight: 700;
}

.competition-section {
	padding: 8px 48px;
	display: flex;
	align-items: center;
	height: 40px;
}

.competition-section-label-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.competition-section-label {
  font-size: 14px;
  font-weight: 400;
  color: #434343;
}

.competition-gender-label {
  color: #8C8C8C;
  @include OpenSansFont(400, 16px, 11px);
  font-style: normal;
}

.select-all-competitions-toggle {
  width: 42px;
  height: 24px;
}

.modal-footer-container {
	border: none;
	justify-content: start;
	padding: 24px;
	gap: 8px;

	& > .btn-primary,
	& > .btn-primary:hover {
		background-color: #049be5;
		border-color: #049be5;
		color: white;
		font-weight: 700;
		padding: 8px 24px;
	}

	& > .btn-secondary,
	& > .btn-secondary:hover {
		background-color: inherit;
		color: #000000;
		border-color: #000000;
		font-weight: 700;
		padding: 8px 24px;
	}
}

.competition-section-container {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
}

.competition-section-checkbox-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.competition-section-checkbox-container {
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 6px;
}

.check-icon {
  background-image: url('../../../../../../../assets/icons/general/check.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}
