@import '../../../../scss/custom/media_query';
@import '../../../../global/fonts.scss';

$gap: 16px;
// Custom Entities Listing
.listing-header {
  gap: $gap - 6px;
  padding: 22px;
  border-radius: 4px;
  border-bottom: 1px solid #D9D9D9FF;
}

.create-btn-icon {
  cursor: pointer;
  top: 17px;
  width: 16px;
  height: 16px;
  transform: translate(-50%, -50%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  margin-left: 9px;
}

.btn-text{
  @include OpenSansFont(400, 20px, 12px);
  color: #FFFFFFFF;
  text-align: left;
  margin-left: 19px;
  top: 15%;
  bottom: 15%;
}

.custom-entity-btn-create-new {
  display: flex;
  width: 140px;
  height: 36px;
  background-color: #1262FAFF;
  gap: $gap - 12px;
  border-radius: 4px;
  padding: 8px;
  top: 16px;
  left: 16px;
}

.header-text{
  @include OpenSansFont(700, 24px, 16px);
  color: #000000FF;
  text-align: left;
}

.table-header {
  margin-left: -4px;
  justify-content: space-between;
}

.table-upper-part {
  border: none;
}

.table-text {
  @include OpenSansFont(400, 24px, 14px);
  color: #000000FF;
}

.custom-entities-btns {
  flex: 1;
  gap: $gap - 8px;
  margin-left: -10px;
}

.custom-entities-btn {
  cursor: pointer;
  width: 32px;
  height: 32px;
  gap: 0;
  border-radius: 4px;
}

.role-delete-btn {
  margin-left: 40px;
}

.custom-entities-search-input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

// Search buttons
.search-bar-btns {
  gap: $gap;
  display: flex;
}

.custom-entities-search-input-field {
  border-radius: 6px 0 0 6px;
  width: 300px;
  height: 36px;
  padding: 8px 12px 8px 12px;
  gap: $gap - 12px;
  border: 1px solid #D9D9D9;
  background: #FFF;
}

.custom-entities-search-button {
  width: 36px;
  height: 36px;
  border-radius: 0 6px 6px 0;
  border: none;

}

.advanced-filters-toggle-button {
  width: 36px;
  height: 36px;
  border-radius: 6px;
  border: none;
}


.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: none;
}

.btn-secondary:hover, .btn-secondary.focus {
  background: #8C8C8C;
}

.search-btns-icons {
  cursor: pointer;
  display: flex;
  width: 16px;
  height: 16px;
  transform: translate(-50%, -50%);
  position: relative;
  top: 7px;
  left: 6px;

}

.advance-filters-toggled {
  background: #8C8C8C;
  border: none;
  outline: none;
  box-shadow: none;

}

.table-span {
  margin-left: -13px;
}

.custom-entities-listing-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none;
  border-radius: 0.5rem;
  margin-bottom: 15px;
  // min-height: 650px;
}

// Delete Modal
.custom-entities-delete-modal{
  position: absolute;
  top: 25%;
  left: 40%;
  transform: translate(-50%, -50%);
}

.custom-entities-delete-modal-text-header {
  @include OpenSansFont(700, 28px, 20px);
  text-align: left;
  color: #0F172AFF;
}

.custom-entities-delete-modal-text {
  @include OpenSansFont(400, 20px, 14px);
  text-align: left;
}

.custom-entities-delete-modal-btns {
  float: right;
  gap: $gap + 8px;
  display: flex;
}

.cancel-delete-btn {
  border: 1px solid #000000FF;
}

.cancel-delete-btn-text {
  @include OpenSansFont(700, 20px, 14px);
  text-align: left;
}

.confirm-delete-btn {
  background: #E25160FF;
}

.confirm-delete-btn-text {
  @include OpenSansFont(700, 20px, 14px);
  text-align: left;
}

// Advance Filters
.advance-filters-header {
  margin-left: -4px;
  justify-content: space-between;
  padding: 16px 0 0 0;

}

.advance-filters-group {
  flex-direction: row;
  display: flex;
  gap: $gap - 6px;
}

.custom-entities-advanced-search-labels {
  @include OpenSansFont(600, 20px, 14px);
  text-align: left;
  color: #0F172AFF;
}

.custom-entities-pagination {
  @include OpenSansFont(400, 20px, 14px);
  padding-left: 0;
  margin-top: 2%;

  // General
  .active {
    a {
      color: #fff;
      cursor: default;
      background-color: #000000FF;
      border: 1px solid #D9D9D9FF;
    }
  }

  .next {
    a {
      border-radius: 0 6px 6px 0;
    }
  }

  .previous {
    a {
      border-radius: 6px 0 0 6px;
    }
  }

  li {
    display: inline-block;
    text-align: -webkit-match-parent;

    a {
      position: relative;
      float: left;
      padding: 6px 12px;
      line-height: 1.5;
      color: #000000FF;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #D9D9D9FF;
    }
  }


  [role='button'] {
    cursor: pointer;
  }
}

table.content-list-table {
  display: block;
  overflow-x: auto;
  border: none;

  .w-title {
    a {
      color: black;
    }
  }

  th,
  td {
    border: none;
  }

  tbody,
  thead {
    display: table;
    width: 100%;
    border: none;
    border-radius: 4px 4px 0 0;
  }
}

@media only screen and (max-width: 1920px){
  .custom-entities-search-input-group {
    width: 400px;
  }
}


@media only screen and (max-width: 768px){
  .custom-entities-search-input-group {
    width: 300px;
  }
}


